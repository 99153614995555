import {createClient} from "@sanity/client";
import imageUrlBuilder from '@sanity/image-url'
import React from "react";

export default createClient({
    projectId: "txgv9bvb", // find this at manage.sanity.io or in your sanity.json
    dataset: "production", // this is from those question during 'sanity init'
    useCdn: true,
    apiVersion: '2022-03-07',
});

export function paragraph(input) {
    return(
        <div>
            {input?.map((text, index) => (
                <p key={index}>{text.children.text}</p>
            ))}
        </div>
    );
}