import React from 'react';

const Referanser = () => {
    return (
        <div>
            Her kommer referanser
        </div>
    );
};

export default Referanser;