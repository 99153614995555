import React from 'react'
import sanityClient from "../../client";
import imageUrlBuilder from '@sanity/image-url'

// Get a pre-configured url-builder from your sanity client
const builder = imageUrlBuilder(sanityClient)

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional
// parameters:
function urlFor(source) {
    if (!source) return '/Tenker.png';
    try{
        return builder.image(source).url()
    }catch (error){
        return '/Tenker.png';
    }
}

export default function image(source){
    return(
        <img className="image" src={urlFor(source)} alt='/Tenker.png' />
    )
}