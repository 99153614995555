import React, { useEffect, useState } from 'react';
import "./BottomTop.css";

const BottomTop = () => {
    const [isBottom, setIsBottom] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrolledToBottom = window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - 400;
            setIsBottom(scrolledToBottom);
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Check scroll position initially in case the page is already scrolled
        handleScroll();

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty array means this effect runs once on mount and cleanup on unmount

    const scroll = () => {
        if (isBottom) {
            window.scrollTo(0, 0);
        } else {
            window.scrollTo(0, document.body.scrollHeight);
        }
    };

    return (
        <div onClick={scroll} className="scroll-arrow">
            <img className="arrow" style={{
                transform: isBottom ? "rotate(180deg)" : "rotate(0deg)"
            }} src="/arrow.png" alt="Scroll arrow"/>
        </div>
    );
};

export default BottomTop;
