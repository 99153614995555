import React, {useEffect, useRef} from 'react';
import './SimpleCss.css';
import Contact from "../../elements/contact/Contact";
import FullImage from "../../elements/images/flipImage";
import sanityClient, {paragraph} from "../../client";
import image from "../../elements/images/imageService";

const safeDocument = typeof document !== 'undefined' ? document : {};
const SimpleGrid = () => {
    const [collection, setCollection] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const [element, setElement] = React.useState({
        "Nummer": "0",
        "Date": "0",
        "Location": "0",
        "Capacity": "0",
        "Speakers": "0",
        "Solgt": "0"

    });
    const [page, setPage] = React.useState(0);
    const scrollBlocked = useRef();
    const html = safeDocument.documentElement;
    const { body } = safeDocument;

    useEffect(() => {
        sanityClient.fetch(`*[_type == "inkunabel" && vis]{
  nummer,
    solgt, 
    bakside,
    forside,
    beskrivelse[]{
              children[0]{
              text
              }
            },
    year,
    boknavn,
    sted,
    navn,
    trykker,
    slug
}| order(nummer asc)`).then((data) => {
            setCollection(data);
        }).catch(console.error);
    }, []);

    const showModal = (element, page) => {
        setPage(page);
        setElement(element);
        setShow(true);
        if (!body || !body.style || scrollBlocked.current) return;

        const scrollBarWidth = window.innerWidth - html.clientWidth;
        const bodyPaddingRight =
            parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

        html.style.position = 'relative'; /* [1] */
        html.style.overflow = 'hidden'; /* [2] */
        body.style.position = 'relative'; /* [1] */
        body.style.overflow = 'hidden'; /* [2] */
        body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

        scrollBlocked.current = true;
    }
    const onClose = () => {
        setShow(false);
        setPage(0);
        if (!body || !body.style || !scrollBlocked.current) return;

        html.style.position = '';
        html.style.overflow = '';
        body.style.position = '';
        body.style.overflow = '';
        body.style.paddingRight = '';
        scrollBlocked.current = false;
    }
    const onNext = () => {
        if (page < collection.length - 1) {
            showModal(collection[page + 1], page + 1);
        }else {
            showModal(collection[0], 0);
        }
    }
    const onPrevious = () => {
        if (page > 0) {
            showModal(collection[page - 1], page - 1);
        }
        else {
            showModal(collection[collection.length - 1], collection.length - 1);
        }

    }

    function items(collection) {
        return collection?.map((row, i) => (
            <Item key={i} index={i} props={row} showModal={showModal}/>
        ));
    }

    return (
        <div className="grid-container-vertical" style={show?{}:{overflow:"hidden"}}>
            {
                collection?items(collection):""
            }
            <Modal onClose={() => onClose()} show={show} element={element} onPrevious={onPrevious} onNext={onNext}/>
        </div>

    );
};

export default SimpleGrid;

const Item = props => {
    return (
        <div key={props.props.EventID} className={"grid-item" + (props.props.solgt ? (" item-sold"):(""))} onClick={
            (e) => {
                e.preventDefault();
                props.showModal(props.props, props.index)
            }
        }>
            <h3>{'\u2116'} {props.props["nummer"]} {props.props.solgt?(" (Solgt)"):("")}</h3>
            <div className="item-image">
                {image(props.props["forside"])}
            </div>
            <p>{props.props["year"]}</p>
        </div>
    );

}


const Modal = props => {
    const [visibleField, setVisibleField] = React.useState("image");
    const onChangeVisibleField = (field) => {
        setVisibleField(field);
    }

    if (!props.show || props.element === null || props.element === undefined) {
        return null;
    }
    return (
        <div className="grid-modal" onClick={props.onClose}>
            <div className="grid-modal-content" onClick={e => e.stopPropagation()}>
                <div className="grid-modal-header">
                    <h2>{props.element.Nummer}</h2>
                    <span className="close grid-modal-close modal-button grid-modal-button" onClick={
                        (e) => {
                            e.preventDefault();
                            props.onClose();
                        }
                    }>&times;</span>
                </div>
                <div className="grid-modal-body">
                    <button className="prev nav-button grid-modal-button modal-button" onClick={
                        (e) => {
                            e.preventDefault();
                            props.onPrevious();
                        }
                    }>&#8249;</button>

                    <div
                        className={(visibleField === "image" ? ("") : ("hide-mobile")) + " grid-modal-body-left content"}>
                        <FullImage info={props.element}/>
                        <h4 className="helpertext">Trykk på arket for å se den andre siden <i className="fa-solid fa-rotate"></i></h4>
                    </div>
                    <div
                        className={(visibleField !== "image" ? ("") : ("hide-mobile")) + " grid-modal-body-right content"}>
                        <RightContent element={props.element} display={visibleField} setVisibleField={setVisibleField}/>
                    </div>
                    <button className="next nav-button grid-modal-button modal-button" onClick={
                        (e) => {
                            e.preventDefault();
                            props.onNext();
                        }

                    }>&#8250;</button>
                </div>
                <div className="grid-modal-footer">
                    <div className={"mode-button mode-button-mobile " + (visibleField==="image"?("active-mode"):(""))} onClick={()=>onChangeVisibleField("image")}><i className="fa-regular fa-image"></i></div>
                    <h1 className={"mode-button mode-button-mobile " + (visibleField==="info"?("active-mode"):(""))} onClick={() => onChangeVisibleField("info")}><i className="fa-solid fa-info"></i></h1>
                    <h1 className={"mode-button mode-button-mobile " + (visibleField==="contact"?("active-mode"):(""))} onClick={() => onChangeVisibleField("contact")}><i className="fa-regular fa-envelope"></i></h1>

                </div>
            </div>
        </div>
    );
}

const salgsstatus = (solgt) => {

    if (solgt === undefined || solgt === null || solgt === "" || solgt === 0 || !solgt) {
        return "Tilgjengelig";
    }
    if (solgt) {
        return "I privat eie";
    } else {
        return "Tilgjengelig";
    }
}



const RightContent = (props) => {
    return (
        <div className="grid-modal-right-content">
            <div className="grid-modal-right-content-body">
                <div className={(props.display==="info"?(""):("hide-mobile")) +" info"} style={{display: props.display==="contact"?("none"):("")}}>
                    <div className="content-title">
                        <h3 className="title-text">Inkunabel {'\u2116'} {props.element["nummer"]}</h3>
                        <p className="mode-button hide-mobile" onClick={

                            (e) => {
                            e.preventDefault();
                            props.setVisibleField("contact")
                        }
                        }>

                            <i className="fa-regular fa-envelope"></i></p>

                    </div>
                    <div className="inline">
                        <p>Bokas navn:</p>
                        <p>{props.element["boknavn"]}</p>
                    </div>
                    <div className="inline">
                        <p>Trykket i:</p>
                        <p>{props.element["sted"]}</p>
                    </div>
                    <div className="inline">
                        <p>Trykket av:</p>
                        <p>{props.element["trykker"]}</p>
                    </div>
                    <div className="inline">
                        <p>År:</p>
                        <p>{props.element["year"]}</p>
                    </div>
                    <div className={"inline " + (props.element["solgt"]?("owned-true "):(""))} >
                        <p>Solgt:</p>
                        <p>{salgsstatus(props.element["solgt"])}</p>
                    </div>
                    <div className="inline">
                        {paragraph(props.element["beskrivelse"])}
                    </div>
                    <div className="whitespace"/>
                </div>
                <div className={props.display==="contact"?(""):("hide-mobile")} style={{display: props.display==="contact"?(""):("none")}}>
                    <div className="content-title contact-title">
                        <h3 className="title-text ">Ta kontakt</h3>
                        <p className="mode-button hide-mobile" onClick={

                            (e) => {
                                e.preventDefault();

                                props.setVisibleField("info")
                            }
                        }>

                            <i className="fa-solid fa-info"></i></p>

                    </div>
                    <Contact className="grid-modal-contact" element={props.element}/>
                </div>
            </div>
        </div>
    );

}