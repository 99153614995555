import './App.css';
import {Navigate, Routes, Route} from "react-router-dom";
import React from "react";
import Home from "./pages/home";
import Referanser from "./pages/referanser/referanser";



function App() {
  return (
      <div className="App">
        <Routes>
          <Route path="/" element={<Home/>}/>
            <Route path="/referanser" element={<Referanser/>}/>
          <Route path="/*" element={<Navigate replace to="/"/>}/>
        </Routes>
      </div>
  );
}

export default App;
