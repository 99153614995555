import React, {useEffect} from 'react';
import sanityClient, {paragraph} from "../client";
import Contact from "../elements/contact/Contact";
import SimpleGrid from "../elements/grid/SimpleGrid";
import BottomTop from "../elements/scroll/BottomTop";


const Home = () => {
    const [headerText, setHeaderText] = React.useState(null);
    const [contactText, setContactText] = React.useState(null);

    useEffect(() => {
        sanityClient.fetch(`*[_type == "paragraph" && slug.current == "Header"]{
            text[]{
              children[0]{
              text
              }
            }
        }`).then((data) => {
            setHeaderText(data[0]);
        }).catch(console.error);


        sanityClient.fetch(`*[_type == "paragraph" && slug.current == "Kontakt"]{
            text[]{
              children[0]{
              text
              }
            }
        }`).then((data) => {
            setContactText(data[0]);
        }).catch(console.error);

    }, []);

    return (
        <div>
            <BottomTop/>
            <h1>Inkunabulasjon</h1>
            {paragraph(headerText?.text)}
            <SimpleGrid/>
            <h2>Kontakt</h2>
            {paragraph(contactText?.text)}
            <Contact/>
        </div>
    );
};

export default Home;